import React from 'react';
import { ReactComponent as BenwaysSplash } from './benways_anim.svg';
import { ReactComponent as BenwaysLoader } from './benways_loader.svg';

import './Loader.css';

function Loader() {
    return (
        <div>
            <BenwaysSplash />
            {/* <BenwaysLoader /> */}
        </div>

    );
}

export default Loader;
