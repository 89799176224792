import React, { useState, useEffect } from 'react';
import Loader from './Components/Loader';
// import FileTree from './Components/FileTree';
import './App.css';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 4000);
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    "Benways App is not ready yet!"
  );
}

export default App;
